module.exports = [{
      plugin: require('../node_modules/gatsby-theme-i18n/gatsby-browser.js'),
      options: {"plugins":[],"defaultLang":"pl","configPath":"/opt/project/i18n/config.json"},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"locales":"./i18n","i18nextOptions":{"debug":true,"defaultNS":"common","ns":["common"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"language":"pl","title":"Agencja Pracy Yes!People","titleTemplate":"%s · Agencja Pracy Yes!People","description":"Nowoczesna agencja pracy z Wrocławia specjalizująca się w rekrutacji pracowników ze Wschodu.","siteUrl":"https://yespeople.pl","keywords":"agencja pracy wrocław, agencja pracy tymczasowej wrocław, pracownicy z ukrainy wrocław, rekrutacja z ukrainy wrocław, pracownicy ze wschodu wrocław","openGraph":{"type":"website","url":"https://yespeople.pl","title":"Agencja Pracy Yes!People","description":"Nowoczesna agencja pracy z Wrocławia specjalizująca się w rekrutacji pracowników ze Wschodu.","images":[{"url":"https://yespeople.pl/cover.png","width":1200,"height":630,"alt":"Agencja Pracy Yes!People"}],"site_name":"Agencja Pracy Yes!People"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Yes!Poeple","short_name":"Yes!Poeple","start_url":"/","background_color":"#fff","theme_color":"#049669","display":"minimal-ui","icon":"src/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0249f516db87e03cc9e25bb5dfa56982"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
