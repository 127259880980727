exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dla-pracodawcy-js": () => import("./../../../src/pages/dla-pracodawcy.js" /* webpackChunkName: "component---src-pages-dla-pracodawcy-js" */),
  "component---src-pages-dla-pracownika-js": () => import("./../../../src/pages/dla-pracownika.js" /* webpackChunkName: "component---src-pages-dla-pracownika-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-wyslano-js": () => import("./../../../src/pages/wyslano.js" /* webpackChunkName: "component---src-pages-wyslano-js" */)
}

